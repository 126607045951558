import React from 'react';
import { graphql } from 'gatsby';
import { Container } from 'theme-ui';
import Layout from '@solid-ui-layout/Layout';
import Divider from '@solid-ui-components/Divider';
import Header from '@solid-ui-blocks/Header/Block01';
import Tabs from '@solid-ui-components/Tabs';
import Companies from '@solid-ui-blocks/Companies/Block01';
import Footer from '@solid-ui-blocks/Footer/Block01';
import { normalizeBlockContentNodes } from '@blocks-helpers';
import theme from './_theme';
import styles from './_styles';
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block02';
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block05';
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01';
import { Helmet } from 'react-helmet';
import ContactModal from '@solid-ui-blocks/ContactModal/Block01';
import Hero from '@solid-ui-blocks/Hero/Block02';
import { ReactCompareSlider, ReactCompareSliderHandle } from 'react-compare-slider';
import { StaticImage } from 'gatsby-plugin-image';
import Strategies from '@solid-ui-blocks/Stats/Block01';



const DepartamentosJuridicosPage = props => {
    const { allBlockContent } = props.data;
    const content = normalizeBlockContentNodes(allBlockContent?.nodes);

    return (
        <Layout theme={theme} {...props}>
            <Helmet>
                <title>Dados Jurídicos para aplicações de negócio | JUIT</title>
                <meta name="title" content="Dados Jurídicos para aplicações de negócio | JUIT" />
                <meta
                    name="description"
                    content="Com os dados estruturados da JUIT você pode usar a jurimetria para estudar teses complexas, melhorar políticas de acordo, consumir dados via API e muito mais."
                />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://juit.io/departamentos-juridicos" />
                <meta property="og:title" content="Dados Jurídicos para aplicações de negócio | JUIT" />
                <meta
                    property="og:description"
                    content="Com os dados estruturados da JUIT você pode usar a jurimetria para estudar teses complexas, melhorar políticas de acordo, consumir dados via API e muito mais."
                />
                <meta property="og:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-home.png" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://juit.io/departamentos-juridicos" />
                <meta property="twitter:title" content="Dados Jurídicos para aplicações de negócio | JUIT" />
                <meta
                    property="twitter:description"
                    content="Com os dados estruturados da JUIT você pode usar a jurimetria para estudar teses complexas, melhorar políticas de acordo, consumir dados via API e muito mais."
                />
                <meta property="twitter:image" content="https://storage.googleapis.com/juit-io-logos/JUIT-metabg-home.png" />
                <script id="pixel-script-poptin" src="https://cdn.popt.in/pixel.js?id=93963828aa058" async="true"></script>
                <script type='text/javascript'>
                    {`
                    const isBrowser = typeof window !== 'undefined';
                    const _linkedin_partner_id = '4539113';
                    
                    if (isBrowser) {
                        window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(_linkedin_partner_id);
                        (function (l) {
                            if (!l) {
                                window.lintrk = function (a, b) {
                                    window.lintrk.q.push([a, b]);
                                };
                                window.lintrk.q = [];
                            }
                            var s = document.getElementsByTagName('script')[0];
                            var b = document.createElement('script');
                            b.type = 'text/javascript';
                            b.async = true;
                            b.src = 'https://snap.licdn.com/li.lms-analytics/insight.min.js';
                            s.parentNode.insertBefore(b, s);
                        })(window.lintrk);
                    }
                    `}
                </script>
                <noscript>
                    {`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=4539113&fmt=gif" />`}
                </noscript>
            </Helmet>
            {/* Modals */}
            <ContactModal />
            {/* Blocks */}
            <Header content={content['header-light']} permanentBlack menuJustify="space-around" />
            <Divider space="5" />
            <Divider space="5" />
            <FeatureTwo content={content['feature-two']} reverse />
            <Companies content={content['companies']} />
            <Divider space="5" />
            <Container variant="full" sx={styles.heroContainer}>
                <Hero content={content['hero']} />
                <Divider space="3" />
                <Container sx={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <ReactCompareSlider
                        handle={<ReactCompareSliderHandle buttonStyle={{ color: '#f9b122', backgroundColor: '#2d3537' }} linesStyle={{ color: '#f9b122' }} />}
                        itemOne={<StaticImage src="./first.png" />}
                        itemTwo={<StaticImage src="./second.png" />}
                    />
                </Container>
                <Divider space="4" />
            </Container>
            <Divider space="5" />
            <Divider space="5" />
            <Container variant="wide" sx={styles.tabsContainer}>
                <Tabs space={4} variant="pill" className="3em1tabs">
                    <FeatureOne content={content['tab-feature-one']} paddingLeft={'10px'} />
                    <FeatureTwo content={content['tab-feature-two']} />
                    <FeatureThree content={content['tab-feature-three']} />
                    <FeatureThree content={content['tab-feature-four']} />
                </Tabs>
            </Container>
            <Divider space="5" />
            <Divider space="5" />
            <Divider space="5" />
            <Divider space="5" />
            <Container variant="full" sx={styles.strategiesContainer}>
                <Divider space={-6} />
                <Strategies content={content['strategies']} />
            </Container>
            <Divider space="5" />
            <Footer content={content['footer']} />
        </Layout>
    );
};

export const query = graphql`
    query departamentosJuridicosBlockContent {
        allBlockContent(filter: { page: { in: ["site/departamentos-juridicos", "shared"] } }) {
            nodes {
                ...BlockContent
            }
        }
    }
`;

export default DepartamentosJuridicosPage;
